import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import {
  ReactiveBase,
  ResultList,
  MultiList,
  SelectedFilters,
  DynamicRangeSlider,
  DataSearch,
  ReactiveList
} from "@appbaseio/reactivesearch";
import "./styles.css";

class Labs extends Component {
    constructor(props) {
        super(props);
        this.state = {apiauthtoken: "1234"};
    }
      componentDidMount() {
        console.log('on component mount');
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
          //console.log(user);
          this.setState({apiauthtoken: user.signInUserSession.idToken.jwtToken});
        }).catch(e => {
          console.log(e);
          this.setState({authState: 'signIn'});
        });
      }
  onData(item) {

    const { id, lab_id, lab_template, lab_name, lab_description, startup_time, cloudcraft_url, highlight } = item;
    let hyperlinked_title = '<a href="' + cloudcraft_url + '" target="_blank">'+lab_name+'</a>'

    return (
      <>
      <ReactiveList.ResultListWrapper>
       <div className="labsearch-result">
        <ResultList key={item._id}>
        <ResultList.Image src="https://learn.acloud.guru/static/images/svg-icons/search-labs.svg" small={true}/>
          <ResultList.Content>
            <ResultList.Title  dangerouslySetInnerHTML={{
                                    __html: hyperlinked_title
                                }} ></ResultList.Title>
          <ResultList.Description> 
              <span className="looker_ratings" title ="Open Feedback in Looker"><a target="_blank" href={"https://acloudguru.looker.com/looks/678?f[dim_lab_ratings.lab_id]=" + id }> View Student Feedback  </a></span>
          
              <span className="lim_page" title ="Open Lab Issue Manager history in CloudCraft"><a target="_blank" href={"https://cloudcraft.linuxacademy.com/issues/detail/" + id }> View Issue History</a></span>

          </ResultList.Description>
          <ResultList.Description>{startup_time ? (<span>Average Launch Time: {startup_time} seconds</span>):(<span>No Startup Time Available</span>)
          }
          </ResultList.Description>
          <ResultList.Description>
          {highlight.lab_template ?
                      (<span className="lab_description"><a target="_blank" href={"https://cloudcraft.linuxacademy.com/labs/details/" + id +"/step/laas"}><i class="fas fa-code"></i>  Template</a></span>)
                          :<span></span>
          }</ResultList.Description>
          <ResultList.Description>
          {highlight.lab_description ?
            <span className="lab_description"><a target="_blank" href={"https://cloudcraft.linuxacademy.com/labs/details/" + id +"/step/laas"}><i class="fa fa-pen"></i>  Description</a></span>
            :<span></span>
          }</ResultList.Description>
          <ResultList.Description dangerouslySetInnerHTML={{
                                    __html: highlight.lab_description
                                }}>

                                </ResultList.Description>
            <ResultList.Description>
              {
              highlight.lab_description ? (
                <ResultList.Description dangerouslySetInnerHTML={{
                  __html: highlight.lab_description
              }}></ResultList.Description>
            ):(<span></span>)}
                            </ResultList.Description><br />


            <ResultList.Description>
            {
              highlight.lab_objectives ? (
              <span className="lab_description"><a target="_blank" href={"https://cloudcraft.linuxacademy.com/labs/details/" + id +"/step/objectives"}><i class="fas fa-tasks"></i>  Objectives</a></span>)
              : <span></span>
            }
              </ResultList.Description>
            <ResultList.Description dangerouslySetInnerHTML={{
                                    __html: highlight.lab_objectives
                                }}></ResultList.Description><br />

        <ResultList.Description>
        {
              highlight.lab_guide ? 
          <span className="lab_description"><a target="_blank" href={"https://cloudcraft.linuxacademy.com/labs/details/" + id +"/step/videos"}><i class="fas fa-info-circle"></i>  Lab Guide</a></span>
          : <span></span>
              }
          </ResultList.Description>
            <ResultList.Description dangerouslySetInnerHTML={{
                                    __html: highlight.lab_guide
                                }}></ResultList.Description>
          </ResultList.Content>
        </ResultList></div>
      </ReactiveList.ResultListWrapper>
      </>
    );
  }

  render() {
    const LessonSearch = () => (
      <div>
        <h2>Remediation Hub</h2>
      </div>
    );
    
    const LabSearch = () => (
      <div>
        <h2>LabSearch</h2>
      </div>
    );
    return (
      <>
      <div>
    </div>
      <div className="container-fluid">
        <ReactiveBase
          app="v0"
          headers={{Authorization: `Bearer ${this.state.apiauthtoken}`}}
          url="https://xnwmdyeu3k.execute-api.us-east-1.amazonaws.com"
        >
          <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="search-box">
                <DataSearch
                  componentId="Keywords"
                  placeholder="Search for Labs"
                  dataField={["lab_template", "lab_description", "lab_objectives", "lab_guide"]}
                  highlight={true}
                  autosuggest={true}
                  highlightField={["lab_template", "lab_description", "lab_objectives", "lab_guide"]}
                  searchInputId="NameSearch"
                  autosuggest={false}
                  iconPosition="right"
                  renderError={error => (
                    <div>
                      Something went wrong with DataSearch
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >

            </div>
          </nav>

          <div className="row">
            <div className="col-8 col-sm-4 scroll">
              <div className="box">
              <MultiList
                  dataField="lab_type.keyword"
                  title="Lab Type Filters"
                  componentId="labtypeReactor"
                  placeholder="Filter Lab Type"
                  showSearch={false}
                  showFilter={true}
                  filterLabel="Support Tier"
                  react={{
                    and: [
                      "Keywords",
                      "publishedReactor",
                      "startupSecondsSlider"
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong with the MultiList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>

              <div className="row">
              <MultiList
                    dataField="published.keyword"
                    title="Published Filters"
                    componentId="publishedReactor"
                    placeholder="Published"
                    showSearch={false}
                    showFilter={true}
                    defaultValue={["True"]}
                    filterLabel="Published Filters"
                    react={{
                      and: [
                        "Keywords",
                        "labtypeReactor",
                        "startupSecondsSlider"
                      ]
                    }}
                  renderError={error => (
                    <div>
                      Something went wrong with course MultiList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>

              <div className="row">
              <DynamicRangeSlider
                componentId="startupSecondsSlider"
                dataField="startup_time"
                title="Startup Time"
                filterLabel="Startup Time"
                snap="true"
                tooltipTrigger="hover"
                showHistogram={true}
                showFilter={true}
                rangeLabels={(min, max) => (
                  {
                    "start": min + " seconds",
                    "end": max + " seconds"
                  }
                )}
                defaultValue={(max) => (
                  {
                    "start": 0,
                    "end": max
                  }
                )}
                react={{
                  and: [
                     "Keywords",
                        "labtypeReactor",
                        "publishedReactor"
                  ]
                }}
              />
              </div>

            </div>
            <div className="col">
              <SelectedFilters />
              <ReactiveList
                componentId="queryResult"
                dataField={["startup_time", "lab_name"]}
                from={0}
                size={10}
                renderItem={this.onData}
                pagination={true}
                sortOptions={[
                  {
                    label: "Best Match",
                    dataField: "_score",
                    sortBy: "desc"
                  },
                  {
                  label: "Launch Time - High to Low",
                  dataField: "startup_time",
                  sortBy: "desc"
                  },
                  {
                    label: "Launch Time - Low to High",
                    dataField: "startup_time",
                    sortBy: "asc"
                    },
                  {
                    label: "Lab Name - A to Z",
                    dataField: "lab_name.keyword",
                    sortBy: "asc"
                    },
                  {
                    label: "Lab Name - Z to A",
                    dataField: "lab_name.keyword",
                    sortBy: "desc"
                    }
                ]}
                react={{
                  and: [
                    "labtypeReactor",
                    "publishedReactor",
                    "startupSecondsSlider",
                    "Keywords"
                  ]
                }}
                renderError={error => (
                  <div>
                    Something went wrong with ResultList!
                    <br />
                    Error details
                    <br />
                    {error}
                  </div>
                )}
              />
            </div>

          </div>
        </ReactiveBase>
      </div>
      </>
    );
  }
}

export default withAuthenticator(Labs)
