// Importing combination
import React, {Component} from 'react';
import axios from 'axios';
import LoadingSpinner from './Spinner.js';
import { Auth } from 'aws-amplify';
import ReactJson from 'react-json-view'; 
//import ReactDiffViewer from 'react-diff-viewer';
 
class CourseChanges extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tasks: {},
          open: false,
          loading: false,
        }
      }
      

      togglePanel(e){
        this.setState({open: !this.state.open})
        }

      componentDidMount() {
        console.log('on component mount');

      }
    
      render() {
        return (<div>
        <div onClick={(e)=>this.togglePanel(e)} className='coursechange-header'>
        Show / Hide Changes in JSON </div>
        {this.state.open ? (
        <div className='coursechange-content'>

          <pre><code>{JSON.stringify(this.props.updatejson, null, 2)}</code></pre>
 {/*

        <ReactDiffViewer oldValue={this.props.oldCode} newValue={this.props.newCode} showDiffOnly={true} /> 
         */}
        </div>
        ) : null}
        </div>);
        }

}
 

// Exporting the component
export default CourseChanges;