import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import "./index.css";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import "./index.css";
Amplify.configure(awsExports);
 
ReactDOM.render(
  <Main/>, 
  document.getElementById("root")
);