import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react'
import logo from './images/header.png'; // acg logo
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import Labs from "./Labs"; 
import CourseUpdates from "./CourseUpdates"; 
import Lessons from "./Lessons";
import Assessments from "./Assessments";
import ChangeRecorder from "./ChangeRecorder";
import Roadmap from "./Roadmap";
class Main extends Component {
  render() {
    return (
        <HashRouter>
        <div> <h1><img src={logo} /></h1>
          <ul className="header">
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/lessons">Lessons</NavLink></li>
            <li><NavLink to="/labs">Labs</NavLink></li>
            <li><NavLink to="/assessments">Assessments</NavLink></li>
            <li><NavLink to="/courseupdates">Course Updates</NavLink></li>
            <li><NavLink to="/changerecorder">Cloud Change Recorder</NavLink></li>
            <li><NavLink to="/roadmap">Content Roadmap</NavLink></li>
            <div id="signoutbutton"><amplify-sign-out button-text="Sign Out"></amplify-sign-out></div>
          </ul>
          <div className="content">
            <Route exact path="/" component={Home}/>
            <Route path="/lessons" component={Lessons}/>
            <Route path="/labs" component={Labs}/>
            <Route path="/assessments" component={Assessments}/>
            <Route path="/changerecorder" component={ChangeRecorder}/>
            <Route path="/CourseUpdates" component={CourseUpdates}/>
            <Route path="/roadmap" component={Roadmap}/>
          </div>
        </div>
      </HashRouter>
    );
  }
}
 
export default withAuthenticator(Main);