import React, { Component } from "react";
import co_form_example from './images/co_form_example.jpg'; // acg logo
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'

class Home extends Component {
  render() {
    return (
      <div>
        <div id="outage">
          <h2>Outage Notification</h2>
          <p><span class="monospacedDate">2021-12-16 @ 12:00 UTC</span><br />Content is not currently updating on the Remediation Hub. Data seen will be accurate as of Thursday 16th December AU. This is due to changes to the Auth0 Tenant that authenticates us to CloudCraft</p>
          <p>We are working on a resolution. If you have any queries, please let us know at <a href="https://pluralsight.slack.com/archives/C02AECWARRS">#team-content-quality-remediation</a></p>
        </div>        
        
        <h2>Welcome</h2><br />
        <p> This site was built by the Content Remediation team as part of their initiative to improve content discoverability, and reduce manual work. <br />You can track down lessons, labs, and assessments (quiz and exam questions) using free text search rather than relying on metadata and filter lesson results by course or tier.   <br />It also helps Content Remediation to find existing labs using specific cloud services and code, docker images, GitHub repos, etc. <br /> <br />
        
        
        It is for <b>internal use only </b>and since we're a teeny tiny team we can't guarantee the accuracy of data or availability of the site!</p>
        
        <a href='https://linuxacademy.atlassian.net/wiki/spaces/CON/pages/1697907687/Content+Remediation' target='_blank'><h3>About Content Remediation</h3></a>

        <p>The CR team’s purpose is to maintain and improve our student experience by ensuring our content is current, correct and accessible.</p>
        <p>We look after content in production for currency and accuracy. We are an escalation point for Content Support issues (labs, questions and lessons) and we proactively monitor for changes that may require updates to our content.</p>
        <p>For details of Content Remediation Projects and Initiatives check the <a href='https://linuxacademy.atlassian.net/wiki/spaces/CON/pages/1697842320/Content+Remediation+-+Projects' target='_blank'>Content Remediation - Projects </a>page in Confluence.</p>
        <br />
        <a href='https://form.asana.com/?hash=3f26538ef981a66f9df0740c55138774e03dfe23dffd678b1777a764cfc4c415&id=1172987992170447' target='_blank'><h3>Feedback and Suggestions</h3></a>
        
        
        <p>We would love to hear from pilot users on how this tool is assisting in your duties. Please take some time to let us know, via the <a href='https://form.asana.com/?hash=3f26538ef981a66f9df0740c55138774e03dfe23dffd678b1777a764cfc4c415&id=1172987992170447' target='_blank'>CO Form.</a>  Just select Content Remediation, and <i>a bot or tool CR built</i>.  Especially helpful is if you let us know what the use case is (what task are you working on at the moment) and how is the tool helping, or what extra functionality would help make it even more useful?<br />
        <img src={co_form_example} height={450} width={350} />
        </p>
        <p></p>
        <b>Source:</b><br />
        <div className="links">
                <a
                  target="_blank"
                  href="https://github.com/ACloudGuru/Course_Transcript_Collector"
                  className="btn link"> 
                  <i className="fab fa-github" aria-hidden="true" /> Hub Backend
                </a>
                <a
                  target="_blank"
                  href="https://github.com/ACloudGuru/transcripts_frontend"
                  className="btn link">   
                  <i className="fab fa-github" aria-hidden="true" /> Hub Frontend
                </a>
                
              </div><br />
        <b>Contact Us</b><br />
        <div className="links">
                <a
                  target="_blank"
                  href="https://pluralsight.slack.com/archives/C02AECWARRS"
                  className="btn link">  
                  <i className="fab fa-slack" aria-hidden="true" /> team-content-quality-remediation
                </a>
                
              </div>
              <br /><br />
              <h3>Updates</h3>

              <table id="updateTable">
                <tr>
                  <th class="columnDate">Date</th>
                  <th class="columnAuthor">Author</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-11-17</td>
                  <td>Jon Frame</td>
                  <td>Easily check a lab's issue (LIM) history in CloudCraft from Lab Search results.</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-11-10</td>
                  <td>Stephen Sennett</td>
                  <td>Including Course Tiers in Lessons Results</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-09-29</td>
                  <td>Jon Frame</td>
                  <td>Ordering topics in Lesson search by number of mentions</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-09-22</td>
                  <td>Jon Frame</td>
                  <td>Added answers and explanations to search results for assessments.</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-09-21</td>
                  <td>Jon Frame</td>
                  <td>Lesson, Lab, and Assessment looker rating links added to search results</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-08-30</td>
                  <td>Jon Frame</td>
                  <td>Initial Course Updates release. This will be refined in time! For now you'll have to get your JSON reading goggles on and try to make sense of the JSON delta report which our diligent team of indexers create. Just select a course from the drop-down and you will see all changes we have recorded for the course</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-08-30</td>
                  <td>Jon Frame</td>
                  <td>Added a button to lesson search results where you can see a list of 'topics', along with how many times each topic is mentioned in the lesson. Consider this a form of 'tagging', and a way to see the spread of topics covered in a lesson. This is based on a list containing one keyword, and optional synonyms, for each topic</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-08-30</td>
                  <td>Jon Frame</td>
                  <td>Also added a skill level search refiner for lectures following need from Tech Writing for skill level in reports of missing subtitles</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-08-25</td>
                  <td>Jon Frame</td>
                  <td>Added Chapter filter and label to Lesson search</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-08-21</td>
                  <td>Jon Frame</td>
                  <td>Some UI nips here, some UX tucks there</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-08-10</td>
                  <td>Jon Frame</td>
                  <td>Hands-on Lab Start Time Indexing. This can help us identify and optimise labs which are particularly slow to launch for our students</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-08-05</td>
                  <td>Jon Frame</td>
                  <td>New Subtitled Filter added to lesson search. This will allow the Technical Writing team to track down lessons which are missing subtitles and replaces the date published slider</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-07-29</td>
                  <td>Jon Frame</td>
                  <td>Assessment search is go! We have started indexing all the practice exam and quiz questions so you can find and fix questions.</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-07-14</td>
                  <td>Jon Frame</td>
                  <td>Lab search now also queries the Lab Guide, Objectives, and Description as well as the template. This should improve quality of results, especially where the lab template is bare-bones and all the content is in the lab steps</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-07-13</td>
                  <td>Jon Frame</td>
                  <td>We have started to catch when courses have been replaced with a new version as 'Legacy' tier, to help target content which really needs remediation! The Support Tier filter under 'Lesson Search' will now show you that Legacy content</td>
                </tr>
                <tr>
                  <td class="monospacedDate">2021-07-12</td>
                  <td>Jon Frame</td>
                  <td>The ElasticSearch cluster has been upgraded! Searches should perform a bit better now :c)</td>
                </tr>
              </table>


      </div>
    );
  }
}
 
export default Home;