import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import {
  ReactiveBase,
  ResultList,
  SingleList,
  MultiList,
  SingleDropdownList,
  MultiDropdownList,
  SelectedFilters,
  DynamicRangeSlider,
  StateProvider,
  ReactiveList
} from "@appbaseio/reactivesearch";
import GetAsanaDetails from './GetAsanaDetails.js';
import LookerRatings from './LookerRatings.js';
import CourseChanges from './CourseChanges';
import "./styles.css";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error);
      console.log(errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <p>Something went wrong. </p>;
      }
  
      return this.props.children; 
    }
  }

class CourseUpdates extends Component {
  
    constructor(props) {
        super(props);
        this.asanatasktags = ""
        this.coursename = "xxxx"
        this.GetAsanaDetails = new GetAsanaDetails();
        this.state = {apiauthtoken: "1234"};
        this.onData= this.onData.bind(this);
    }

   // getAsanaTasks(courseid) {
    //  this.GetAsanaDetails.getAsanaTasks(courseid).then(tasks  => {
  //      this.setState({tasks: tasks})

 //     })
 //   }


      componentDidMount() {
        //console.log('on component mount');
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
       //   console.log(user);
          this.setState({apiauthtoken: user.signInUserSession.idToken.jwtToken});
        }).catch(e => {
          console.log(e);
          this.setState({authState: 'signIn'});
        });
      }
  onData(item) {
    const { apigwtoken } = (this.state.apiauthtoken);
    const { course_name, id, course_changes, asana_course_tag_gid, update_date, previous_course_json, replacement_course_json} = item;
    let update_timedate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit', }).format(new Date(update_date))
    let course_hyperlinked = '<span title ="Open Course in CC"><a href="https://cloudcraft.linuxacademy.com/courses/details/'
     + id + '/map" target="_blank">'+course_name + ' ('+id+')</a></span>'
    if (course_changes) {var updatejson = JSON.parse(course_changes)}else {updatejson=""};


    return (
      <>
      <ErrorBoundary>
      <ReactiveList.ResultListWrapper>
       <div className="CourseUpdatesearch-result">
        <ResultList key={item._id}>
          <ResultList.Content>
            <ResultList.Title  dangerouslySetInnerHTML={{
                                    __html: course_hyperlinked
                                }} ></ResultList.Title>
            <ResultList.Description>
             <span>Date: {update_timedate}</span>
             </ResultList.Description>
             {/* split the result page now into half - left half for list of changes, right half for our JSON viewer */}
             <div style={{width: "100%"}} >
               
         {/*    <GetAsanaDetails apigw_token={this.state.apiauthtoken} coursename={course_name} courseid={id} asanataggid={asana_course_tag_gid || "1234"} />
         */}
          {/*  
             <LookerRatings apigw_token={this.state.apiauthtoken} coursename={course_name} courseid={id} asanataggid={asana_course_tag_gid || "1234"} />
            */}
            <div style={{width: "30%", float:"left"}} >
              <div className="coursechange-header">Summary (Work in Progress):</div>
                <ResultList.Description>
                {updatejson.numberOfLessons ? (
                    
                    <span className="lesson-count-change-item">Number of lessons changed from {updatejson.numberOfLessons._expected} to {updatejson.numberOfLessons._received}</span>
                        ): <span></span>}
                </ResultList.Description>
                <ResultList.Description>
                {updatejson.relationships ? (
                    <span className="course-relationship-change-item">Like-for-like (Course Relationship) changed<br /></span>
                        ): <span></span>}
                </ResultList.Description>
                <ResultList.Description> 
                {updatejson.sections ? 
                (Object.keys(updatejson.sections._content).map((sectionkey, i) => (
                  <p key={i}><span></span>
                  <span className="generic-section-change-item">Changes made to Chapter {(parseInt(sectionkey) + 1).toString()}</span>
                </p>
              )
                )):<span></span>}
                </ResultList.Description>

                <ResultList.Description>
                <span className="change_detailed_json">Details: (json here for debug purposes)</span>
                </ResultList.Description>
                </div>
                
                <div style={{"width": "70%", float:"right"}} >
                  { (updatejson != "") ?
                    (
                      <CourseChanges updatejson={updatejson}/> 
                    ):<span></span>

                  }
                 {/*   <CourseChanges changes={course_changes}/> */}
                     
                </div>
             </div>
          </ResultList.Content>
        </ResultList></div>
      </ReactiveList.ResultListWrapper>
      </ErrorBoundary>
      </>
    );
  }

  render() {
    
    return (
      <>
      <div>
    </div>
      <div className="container-fluid">
        <ReactiveBase
          app="v0"
          headers={{Authorization: `Bearer ${this.state.apiauthtoken}`}}
          url="https://q4f336iyw4.execute-api.us-east-1.amazonaws.com"
        >
          <nav className="navbar navbar-expand-lg navbar-light ">

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >

            </div>
          </nav>
          <div className="row">
        {/*   <span className="course_updates_sectionname">Date Range</span>
              <DynamicRangeSlider
                componentId="updateDateSlider"
                dataField="update_date"
                filterLabel="Date Range"
                snap="true"
                tooltipTrigger="hover"
                showHistogram={true}
                showFilter={true}
                renderTooltipData={data => (
                    <h5>
                        {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(data)}
                      </h5>
                )}
                  rangeLabels={(min, max) => (
                    {
                      "start": new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(min),
                      "end": new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(max)
                    }
                  )}
                react={{
                  and: [
                    "courseNameReactor",
                    "queryResult",
                    "courseIDReactor",
                    "asana_course_tag_gid_reactor"
                  ]
                }}
              />
              */}
            <div className="col-8 col-sm-4 scroll"><br />
            <div className="box scroll">
              
            <SingleDropdownList
                componentId="Course"
                dataField="course_name.keyword"
                title="Select a Course"
                className="course_update_dropdown"
                showSearch={true}
                showFilter={true}
                size="1000"
                react={{
                  and: [
                    "queryResult"
                  ]
                }}
                renderError={error => (
                  <div>
                    Something went wrong with course SingleList
                    <br />
                    Error details
                    <br />
                    {error}
                  </div>
                )}
              />
              
              {/*
              <span className="course_updates_sectionname">Select a Course</span>
              <SingleList
                  dataField="course_name.keyword"
                  componentId="courseNameReactor"
                  placeholder="Course Name"
                  showFilter={true}
                  default="DefaultValue"
                  showSearch={true}
                  filterLabel="Course Filters"
                  react={{
                    and: [
                      "updateDateSlider",
                      "courseIDReactor",
                      "queryResult",
                      "asana_course_tag_gid_reactor"

                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong with course SingleList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
                  */}
              </div>
              <div><br />
              <MultiList
                componentId="AsanaTagId"
                dataField="asana_course_tag_gid.keyword"
                title="Asana Course Tags"
                size="1"
                style={{ display: 'none' }}
                showCount={false}
                showCheckbox={false}
                showSearch={false}
                react={{
                  and: [
                    "queryResult",
                    "Course"

                  ]
                }}

            />
              {/*  <span className="course_updates_sectionname">Asana Activity</span><br />
                */}
                <StateProvider componentIds="AsanaTagId"
                    onChange={((prevState, nextState) => {
                      if (nextState.AsanaTagId.aggregations && nextState.AsanaTagId.aggregations["asana_course_tag_gid.keyword"].buckets) { 
                      let asanatasktags = Object.assign({}, this.state.asanatasktags);
                      asanatasktags = Array.prototype.map.call(nextState.AsanaTagId.aggregations["asana_course_tag_gid.keyword"].buckets, s => s.key).toString(); 
                      this.setState({asanatasktags: asanatasktags},  () => { 
                        console.log("Old Asana Tag State", prevState);
                        console.log("New Asana Tag State", nextState);
                         } );
                      }
                      else {
                      let asanatasktags = "";
                      this.setState({asanatasktags: asanatasktags}, () => { 
                        console.log("Old Asana Tag State", prevState);
			                  console.log("New Asana Tag State", nextState);
                      } );
                      }
                    })}
                    />

                <span className="course_updates_sectionname">Recent Asana Activity</span>
                  <GetAsanaDetails key={this.state.asanatasktags} apigw_token={this.state.apiauthtoken} asanataggid={this.state.asanatasktags || ""} />



              </div>
              <div>
                <span className="course_updates_sectionname">Recent ZenDesk Activity</span>

                
                <StateProvider componentIds="Course"
                  onChange={((prevState, nextState) => {
                    if (nextState.Course.value) { 
                    let coursename = nextState.Course.value; 
                    this.setState({coursename: coursename},  () => { 
                      console.log("Old Course State", prevState);
                      console.log("New Course State", nextState);
                      console.log("Trying to find associated ZD tickets for ",coursename)
                      } );
                    }
                    else {
                    let coursename = "";
                    this.setState({coursename: coursename}, () => { 
                      console.log("Old Course State", prevState);
                            console.log("New Course State", nextState);
                    } );
                    }
                  })}
                  />

                <LookerRatings key={this.state.coursename} apigw_token={this.state.apiauthtoken} coursename={this.state.coursename || ""} />

              </div>
              {/*
              <br /><br />
              <div className="box scroll"><span className="course_updates_sectionname">Asana Course Tag GID (to hide)</span>
              <SingleList
                  dataField="asana_course_tag_gid.keyword"
                  componentId="asana_course_tag_gid_reactor"
                  placeholder="Asana Tag ID"
                  showFilter={true}
                  showSearch={true}
                  filterLabel="Asana Tag ID"
                  react={{
                    and: [
                      "updateDateSlider",
                      "courseIDReactor",
                      "asana_course_tag_gid_reactor",
                      "queryResult"

                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong with course SingleList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
                  
              </div>

               
              {/*
              <div className="box scroll"><span className="course_updates_sectionname">Associated Asana Tasks</span>
              <StateProvider componentIds="asana_course_tag_gid_reactor" render={({ searchState }) => (
                <div>
                  Search State: ${JSON.stringify(searchState)}
                </div>
              )}/>
            
              </div>
              <br /><br />

              */}


              <div className="row">

              </div>

            </div>
            <div className="col"><br /><span className="course_updates_sectionname">Course Updates (CloudCraft History)</span>
              <SelectedFilters />
              <ReactiveList
                componentId="queryResult"
                dataField={["update_date"]}
                from={0}
                size={10}
                sortOptions={[
                  {
                    label: "Date - Newest to Oldest",
                    dataField: "update_date",
                    sortBy: "desc"
                    },
                    {
                    label: "Date - Oldest to Newest",
                    dataField: "update_date",
                    sortBy: "asc"
                    }
                  ]}
                renderItem={this.onData}
                excludeFields={["new_course_json", "old_course_json", "outline_json", "previous_course_json", "replacement_course_json"]}
                pagination={true}
                react={{
                  and: [
                    "Course"
                  ]
                }}
                renderError={error => (
                  <div>
                    Something went wrong with ResultList!
                    <br />
                    Error details
                    <br />
                    {error}
                  </div>
                )}
              />
            </div>

          </div>
        </ReactiveBase>
      </div>
      </>
    );
  }
}

export default withAuthenticator(CourseUpdates)
