// Importing combination
import React, {Component} from 'react';
import axios from 'axios';
import LoadingSpinner from './Spinner.js';
import { Auth } from 'aws-amplify';
 
class GetAsanaDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tasks: {},
          loading: false,
        }
      }


      componentDidMount() {
        console.log('on component mount');
        //const {
        //  match: { params }
        //} = this.props;
        //const { asanatagid } = (this.props.asanatagid);
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
          //console.log(user);
          this.setState({apiauthtoken: user.signInUserSession.idToken.jwtToken});
        }).catch(e => {
          console.log(e);
          this.setState({authState: 'signIn'});
        });
          // for dev purposes just using tag id 1200540718343943
          // workspace 941019801419457
          this.setState({ loading: true }, () => {
          //axios.get("https://app.asana.com/api/1.0/workspaces/941019801419457/tasks/search?opt_fields=gid,completed_at,completed,created_at,assignee,name,tags&tags.any=1200540718343943", { headers: {"Authorization" : `Bearer 1/1172686652505983:b3837dbb5b07a4110713bc71e023b444`} })
          //let tags = Array.prototype.map.call(this.props.asanataggid, s => s.key).toString();
          axios.get(`https://c916rhj5id.execute-api.us-east-1.amazonaws.com/v0/api/1.0/workspaces/941019801419457/tasks/search?opt_fields=gid,completed_at,completed,created_at,assignee,name,tags&tags.any=${this.props.asanataggid}`, { headers: {"Authorization" : `Bearer ${this.props.apigw_token}`}})
          //https://c916rhj5id.execute-api.us-east-1.amazonaws.com/v0
          //axios.get('https://dog.ceo/api/breeds/image/random')
          ///workspaces/941019801419457/tasks/search?opt_fields=gid,completed_at,completed,created_at,assignee,name,tags&tags.any=1200540718343943", { headers: {"Authorization" : `Bearer 1/1172686652505983:b3837dbb5b07a4110713bc71e023b444
          .then(response => {
               this.setState({ 
                tasks: response,
                   loading: false, });
             })
             .catch(error => {
                console.log(error);
                this.setState({ 
                    tasks: "No Tasks Found",
                    loading: false, 
                    });
              });
            })
      }
    
      render() {
        //console.log(this.state.tasks)
        //console.log(typeof (this.state.tasks))
        const { tasks } = (this.state.tasks);
        return (<div className="AsanaTaskWidget">
            {this.state.loading   //if we are still loading
                      ? <LoadingSpinner /> :  
                  this.state.tasks.data ? //if we are not still loading
                            this.state.tasks.data.data ? 
                                    <div className="asanataskcontent"> 
                                        {Object.keys(this.state.tasks.data.data).map((key, i) => (
                                        <p key={i}>{new Intl.DateTimeFormat('en-AU', {  timeZone: 'Australia/Sydney', year: 'numeric', month: 'short', day: 'numeric'}).format(new Date(this.state.tasks.data.data[key].created_at))} 
                                          <span title = "Open task in Asana" className={`asanataskcompleted${this.state.tasks.data.data[key].completed}`}>
                                          <a target="_blank" href={"https://app.asana.com/0/0/" + this.state.tasks.data.data[key].gid}>{this.state.tasks.data.data[key].name}  </a></span>
                                        </p>
                                        ))}
                                    </div>:// there is data from adana nested in the data from api gw //if there is no data field in the returned object
                            <span>{JSON.stringify(this.state.tasks.data.data)}</span> :// if there is no data field in the returned object
                            <span></span>
                                  }</div>)

      }

}
 


// Exporting the component
export default GetAsanaDetails;