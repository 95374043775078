import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import CourseTopics from './CourseTopics';
import {
  ReactiveBase,
  ResultList,
  MultiList,
  DynamicRangeSlider,
  SelectedFilters,
  DataSearch,
  ReactiveList
} from "@appbaseio/reactivesearch";
import "./styles.css";

class Lessons extends Component {
    constructor(props) {
        super(props);
        this.state = {apiauthtoken: "1234"};
    }
      componentDidMount() {
        console.log('on component mount');
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
          console.log(user);
          this.setState({apiauthtoken: user.signInUserSession.idToken.jwtToken});
        }).catch(e => {
          console.log(e);
          this.setState({authState: 'signIn'});
        });
      }
  onData(item) {

    const { id, course_art, course_id, subtitled, lesson_name, section_name, content_id, course_name, video_url, highlight, tier } = item;
    let ratings_link = '<span class="looker_ratings" title ="Open Looker in new Window"><a href="https://acloudguru.looker.com/looks/775?f[f_all_ratings.component_id]=' + id + '" target="_blank">Check Student Feedback</a></span>'
    let subs_url = "https://acloudguru-subtitles-production.s3.amazonaws.com/" +content_id + ".vtt"
    let lesson_title_hyperlinked = '<span title ="Open Lesson on ACG"><a href="' + video_url + '" target="_blank">'+lesson_name + '</a></span>'
    let subs_html = '<div className="links"><i class="far fa-closed-captioning"></i><a target="_blank" href="' + subs_url + '"> Download Subtitles </a></div>'
    
    /*
    let tierIcon = ""

    switch (tier)
    {
      case 'Platinum':
        tierIcon = "&amp;#x26AB; ";
        break;
      case 'Gold':
        tierIcon = "&amp;#x26AB; ";
        break;
      case 'Silver':
        tierIcon = "&amp;#x26AB; ";
        break;
      case 'Bronze':
        tierIcon = "&amp;#x26AB; ";
        break;
      case 'Legacy':
        tierIcon = "&amp;#x2617; "
        break;
      default:
        tierIcon = "&amp;#x2691; ";
        break;
    }
    */
    
    return (
      <>
      <ReactiveList.ResultListWrapper>
        <ResultList key={item._id} className="lesson-result">
          <ResultList.Image src={course_art} href={video_url} small={true}/>
          <ResultList.Content>
            <ResultList.Title dangerouslySetInnerHTML={{
                                    __html: lesson_title_hyperlinked
                                }}>
            </ResultList.Title>
            <ResultList.Title  dangerouslySetInnerHTML={{
                                    __html: ratings_link
                                }} ></ResultList.Title>
            <ResultList.Description>
            <span className="course_tag" title ="Open Course in CloudCraft">Course: <a target="_blank" href={"https://cloudcraft.linuxacademy.com/courses/details/" + course_id+ "/map"}> {course_name}</a> ({tier})</span>
            <span className="course_tag">Chapter: {section_name}</span>
            </ResultList.Description>
            <ResultList.Description dangerouslySetInnerHTML={{
                                    __html: highlight.transcript
                                }}>
            </ResultList.Description>

            <ResultList.Description>
            {subtitled == "True" ? (<span title ="Open Subtitles in new Window"><i class="far fa-closed-captioning" />  <a target="_blank" href={subs_url}>Download Subtitles </a></span>):(<span>No subtitles</span>)}
            </ResultList.Description>


            <ResultList.Description>
            { item["topic-tags"] ?
                    (
                      <CourseTopics tags={item["topic-tags"]}/> 
                    ):<span></span>}

            </ResultList.Description>
          </ResultList.Content>
        </ResultList>
      </ReactiveList.ResultListWrapper>
      </>
    );
  }

  onPopoverClick(marker) {
    return (
      <div
        className="row"
        style={{ margin: "0", maxWidth: "300px", paddingTop: 10 }}
      >
        <div className="col s12">
          <div>
            <strong>{marker.name}</strong>
          </div>
          <p style={{ margin: "5px 0", lineHeight: "18px" }}>
            {marker.address}
          </p>
        </div>
      </div>
    );
  }

  render() {
    const LessonSearch = () => (
      <div>
        <h2>Remediation Hub</h2>
      </div>
    );
    
    const LabSearch = () => (
      <div>
        <h2>LabSearch</h2>
      </div>
    );
    return (
      <>
      <div>
    </div>
      <div className="container-fluid">
        <ReactiveBase
          app="v0"
          headers={{Authorization: `Bearer ${this.state.apiauthtoken}`}}
          url="https://er3tygpb1g.execute-api.us-east-1.amazonaws.com"
        >
          <nav className="navbar navbar-expand-lg navbar-light ">

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
            </div>
          </nav>

          <div className="row">
            <div className="col-8 col-sm-4 scroll">
            <div className="search-box">
                <DataSearch
                  componentId="Keywords"
                  placeholder="Search content of lessons (e.g. 'building a VPC')"
                  className="results-filter"
                  dataField="transcript"
                  highlight={true}
                  autosuggest={false}
                  highlightField="transcript"
                  searchInputId="NameSearch"
                  iconPosition="right"
                  renderError={error => (
                    <div>
                      Something went wrong with DataSearch
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>
              <div className="search_filter">
              <MultiList
                  dataField="tier.keyword"
                  title="Support Tier"
                  className="results-filter"
                  componentId="tierReactor"
                  placeholder="Filter Support Tier"
                  showSearch={false}
                  showFilter={true}
                  filterLabel="Support Tier"
                  react={{
                    and: [
                      "courseReactor",
                      "PublishedDateReactor",
                      "Keywords",
                      "subtitledReactor",
                      "lessonReactor",
                      "sectionReactor","tagReactor","skillReactor",
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>
              {/* */}

           {/*}       */}



              <div className="search_filter">
              <MultiList
                  dataField="subtitled.keyword"
                  title="Subtitles Available"
                  className="results-filter"
                  componentId="subtitledReactor"
                  placeholder="Filter Subtitle Status"
                  showFilter={true}
                  style={{ display: 'none' }}
                  showSearch={false}
                  filterLabel="Lesson is Subtitled"
                  react={{
                    and: [
                      "tierReactor",
                      "PublishedDateReactor",
                      "Keywords",
                      "lessonReactor",
                      "sectionReactor","tagReactor","skillReactor",
                      "courseReactor"
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong with course MultiList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>


              <div className="search_filter">
              <MultiList
                  dataField="course_name.keyword"
                  title="Course Name"
                  className="results-filter"
                  componentId="courseReactor"
                  placeholder="Filter course"
                  size={1000}
                  showFilter={true}
                  sortBy="asc"
                  showSearch={true}
                  filterLabel="Course Name"
                  react={{
                    and: [
                      "tierReactor",
                      "PublishedDateReactor",
                      "Keywords",
                      "subtitledReactor",
                      "lessonReactor",
                      "sectionReactor",
                      "tagReactor","skillReactor",
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong with course MultiList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>
              <div className="search_filter">
              <MultiList
                  dataField="section_name.keyword"
                  title="Chapter Name"
                  className="results-filter"
                  componentId="sectionReactor"
                  placeholder="Filter section"
                  showFilter={true}
                  size={1000}
                  sortBy="asc"
                  showSearch={true}
                  filterLabel="Chapter Name"
                  react={{
                    and: [
                      "tierReactor",
                      "PublishedDateReactor",
                      "Keywords",
                      "subtitledReactor",
                      "lessonReactor",
                      "sectionReactor","tagReactor","skillReactor",
                      "courseReactor"
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong with course MultiList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>
              {/* A JSX comment 

              <div className="search_filter">
              <DynamicRangeSlider
                componentId="PublishedDateReactor"
                dataField="published"
                title="Original Publish Date"
                filterLabel="Published Between"
                snap="true"
                tooltipTrigger="hover"
                renderTooltipData={data => (
                  <h5>
                      {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short'}).format(data)}
                    </h5>
              )}
                rangeLabels={(min, max) => (
                  {
                    "start": new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short'}).format(min),
                    "end": new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short'}).format(max)
                  }
                )}
                react={{
                  and: [
                    "tierReactor",
                    "Keywords",
                    "courseReactor"
                  ]
                }}
              />

              </div>
              */}
              <div className="search_filter">
              <MultiList
                  dataField="topic-tags.Name.keyword"
                  title="Topic Mentions"
                  className="results-filter"
                  componentId="tagReactor"
                  placeholder="Select Topics"
                  showSearch={true}
                  size={1000}
                  sortBy="asc"
                  nestedField="topic-tags"
                  filterLabel="Select Topics"
                  react={{
                    and: [
                      "courseReactor",
                      "PublishedDateReactor",
                      "Keywords",
                      "subtitledReactor",
                      "lessonReactor",
                      "sectionReactor",
                      "tierReactor"
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>
              <div className="search_filter">
              <MultiList
                  dataField="lesson_name.keyword"
                  title="Lesson Name"
                  style={{ display: 'none' }}
                  componentId="lessonReactor"
                  className="results-filter"
                  size={1000}
                  placeholder="Filter lesson"
                  sortBy="asc"
                  showFilter={true}
                  showSearch={false}
                  filterLabel="Lesson Name"
                  react={{
                    and: [
                      "tierReactor",
                      "PublishedDateReactor",
                      "Keywords",
                      "tierReactor",
                      "subtitledReactor",
                      "courseReactor"
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong with course MultiList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>
              <div className="search_filter">
              <MultiList
                  dataField="skill-level.Level.keyword"
                  title="Skill Level"
                  className="results-filter"
                  componentId="skillReactor"
                  showSearch={false}
                  style={{ display: 'none' }}
                  placeholder="Select Skill Level"
                  size={1000}
                  sortBy="asc"
                  nestedField="skill-level"
                  filterLabel="Select Skill Level"
                  react={{
                    and: [
                      "courseReactor",
                      "PublishedDateReactor",
                      "Keywords",
                      "subtitledReactor",
                      "lessonReactor",
                      "sectionReactor",
                      "tierReactor",
                      "tagReactor"

                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>


            </div>
            
            <div className="col">
              <SelectedFilters />
              <ReactiveList
                componentId="queryResult"
                dataField="name"
                className="results-filter"
                from={0}
                size={10}
                renderItem={this.onData}
                pagination={true}
                react={{
                  and: [
                    "courseReactor",
                    "PublishedDateReactor",
                    "tierReactor",
                    "subtitledReactor",
                    "lessonReactor",
                    "sectionReactor","tagReactor","skillReactor",
                    "Keywords"
                  ]
                }}
                renderError={error => (
                  <div>
                    Something went wrong with ResultList!
                    <br />
                    Error details
                    <br />
                    {error}
                  </div>
                )}
              />
            </div>

          </div>
        </ReactiveBase>
      </div>
      </>
    );
  }
}

export default withAuthenticator(Lessons)
