import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify';
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import Question from './Question';
import {
  ReactiveBase,
  ResultList,
  SingleList,
  MultiList,
  SelectedFilters,
  DataSearch,
  ReactiveList
} from "@appbaseio/reactivesearch";
import "./styles.css";

class Lessons extends Component {
    constructor(props) {
        super(props);
        this.state = {apiauthtoken: "1234"};
    }
      componentDidMount() {
        console.log('on component mount');
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
          //console.log(user);
          this.setState({apiauthtoken: user.signInUserSession.idToken.jwtToken});
        }).catch(e => {
          console.log(e);
          this.setState({authState: 'signIn'});
        });
      }
  onData(item) {
    

    const { id, questionPoolId, questionPoolName, questionText, question_pool_memberships, choices, highlight } = item;
    let ratings_link = '<span class="looker_ratings" title ="Open Looker in new Window"><a href="https://acloudguru.looker.com/looks/774?f[dim_quiz_ratings.question_id]=' + id + '" target="_blank">Check Student Feedback</a></span>'
    let question_pool_hyperlined_tag = '<b>Question    </b><span title="Open Question Pool in CloudCraft" class="tag"><a target="_blank" href="https://cloudcraft.linuxacademy.com/assessments/assessment-question-pools/' + questionPoolId + '">Pool: '+questionPoolName+'</a></span>'
    return (
      <>
      <ReactiveList.ResultListWrapper>
        <ResultList key={item._id}>
          <ResultList.Content>
            <ResultList.Description>
            <ResultList.Description dangerouslySetInnerHTML={{
                                    __html: question_pool_hyperlined_tag
                                }} />
            <ResultList.Title  dangerouslySetInnerHTML={{
                                    __html: ratings_link
                                }} ></ResultList.Title>
            <ReactMarkdown>{questionText}</ReactMarkdown>
            </ResultList.Description>
            <ResultList.Description>
            {question_pool_memberships ? (
              Object.keys(question_pool_memberships).map((key, i) => (
            <p key={i}>
              <span title="Open Assessment in CloudCraft" className="linkedassessment"><a target="_blank" href={"https://cloudcraft.linuxacademy.com/assessments/details/" + question_pool_memberships[key].assessment_id + "/info"}>Found in: {question_pool_memberships[key].assessment_name} ({question_pool_memberships[key].assessment_type})</a></span>
            </p>
          )
            )):<span class="nolinkedassessment">Not in any published assessments</span>}
            </ResultList.Description>
          <Question choices={choices}/> 
          </ResultList.Content>
        </ResultList>
      </ReactiveList.ResultListWrapper>
      </>
    );
  }

  onPopoverClick(marker) {
    return (
      <div
        className="row"
        style={{ margin: "0", maxWidth: "300px", paddingTop: 10 }}
      >
        <div className="col s12">
          <div>
            <strong>{marker.name}</strong>
          </div>
          <p style={{ margin: "5px 0", lineHeight: "18px" }}>
            {marker.address}
          </p>
        </div>
      </div>
    );
  }

  render() {
      
    const LessonSearch = () => (
      <div>
        <h2>Remediation Hub</h2>
      </div>
    );
    
    const LabSearch = () => (
      <div>
        <h2>LabSearch</h2>
      </div>
    );
    return (
      <>
      <div>
    </div>
      <div className="container-fluid">
        <ReactiveBase
          app="v0"
          headers={{Authorization: `Bearer ${this.state.apiauthtoken}`}}
          url="https://ilihxqhv3b.execute-api.us-east-1.amazonaws.com"
        >
          <nav className="navbar navbar-expand-lg navbar-light ">

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="search-box">
                <DataSearch
                  componentId="Keywords"
                  placeholder="Search for Questions"
                  dataField="questionText"
                  highlight={false}
                  autosuggest={false}
                  highlightField="questionText"
                  searchInputId="QuestionSearch"
                  iconPosition="right"
                  renderError={error => (
                    <div>
                      Something went wrong with DataSearch
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>
            </div>
          </nav>

          <div className="row no-gutters">
            <div className="col-8 col-sm-4 scroll">
              <div className="box">
              <SingleList
                  dataField="published.keyword"
                  title="Published in an Assessment"
                  componentId="publishedReactor"
                  placeholder="Published"
                  showSearch={false}
                  defaultValue="True"
                  showFilter={true}
                  filterLabel="Published"
                  react={{
                    and: [
                      "courseReactor",
                      "Keywords"
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>

              <div className="box scroll">
              <MultiList
                  dataField="question_pool_memberships.raw"
                  title="Featured in"
                  componentId="membershipReactor"
                  placeholder="Filter Assessment"
                  size={1000}
                  nestedField="question_pool_memberships.assessment_name.keyword"
                  showFilter={true}
                  sortBy="asc"
                  showSearch={true}
                  filterLabel="Assessment Name"
                  react={{
                    and: [
                      "courseReactor",
                      "Keywords",
                      "publishedReactor"
                    ]
                  }}
                  renderError={error => (
                    <div>
                      Something went wrong with course MultiList
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                />
              </div>

            </div>
            <div className="col">
              <SelectedFilters />
              <ReactiveList
                componentId="queryResult"
                dataField="name"
                from={0}
                className="assessmentResults"
                size={10}
                renderItem={this.onData}
                pagination={true}
                react={{
                  and: [
                    "courseReactor",
                    "publishedReactor",
                    "Keywords"
                  ]
                }}
                renderError={error => (
                  <div>
                    Something went wrong with ResultList!
                    <br />
                    Error details
                    <br />
                    {error}
                  </div>
                )}
              />
            </div>

          </div>
        </ReactiveBase>
      </div>
      </>
    );
  }
}

export default withAuthenticator(Lessons)
