// Importing combination
import React, {Component} from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, CartesianAxis, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import numbro from "numbro";


class CourseTopics extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tasks: {},
          open: false,
          loading: false,
        }
      }

      togglePanel(e){
        this.setState({open: !this.state.open})
        }

      componentDidMount() {
        console.log('on component mount');

      }
    
      render() {
        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
        return (<div>
        <div onClick={(e)=>this.togglePanel(e)} className='topicmentions-header'>
        Show / Hide Topic Mentions</div>
     {   this.state.open ? (
        <div className='topicmentions'>

            {(Object.keys((this.props.tags.sort(function(a, b) {return b.Mentions - a.Mentions;}))).map((sectionkey, i) => (
                  <p key={i}><span></span>
                  <span className="topictags">Topic Mentions: {this.props.tags[sectionkey]['Name']} ({this.props.tags[sectionkey]['Mentions']})</span>
                </p>
                
              )))}
              {/*
              <div id="topicchart">
              <PieChart width={400} height={400} onMouseEnter={this.onPieEnter}>
              <Pie
                data={this.props.tags}
                cx={420}
                cy={200}
                startAngle={180}
                endAngle={0}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="Mentions" 
                dataConvertor={value => numbro().unformat(value)}
              >
                {this.props.tags.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              </PieChart>
              </div>
                */}


       {/*         <ResponsiveContainer width="100%" height="100%">
                  <BarChart barCategoryGap={'5%'} layout="vertical" data={this.props.tags} >
                    <XAxis type="number"/>
                    <CartesianGrid strokeDasharray="2 4"/>
                    <Tooltip animationDuration={500}/>
                    <Legend />
                    <Bar isAnimationActive={false} dataKey="Mentions" dataConvertor={value => numbro().unformat(value)} fill="#FF6D30" />
                  </BarChart>
                </ResponsiveContainer>
            */}

 {/*

        <ReactDiffViewer oldValue={this.props.oldCode} newValue={this.props.newCode} showDiffOnly={true} /> 
         */}
        </div>
       ) : null 
      }
        </div>);
        }

}
 


// Exporting the component
export default CourseTopics;