// Importing combination
import React, {Component} from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, CartesianAxis, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import numbro from "numbro";


class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tasks: {},
          open: false,
          loading: false,
        }
      }

      togglePanel(e){
        this.setState({open: !this.state.open})
        }

      componentDidMount() {
        console.log('on component mount');

      }
    
      render() {
        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
        return (<div>
        <div onClick={(e)=>this.togglePanel(e)} className='questiondetails'>
        Show Choices </div>
     {   this.state.open ? (
        <div className='choices'>
            {(Object.keys(this.props.choices ).map((sectionkey, i) => (
                  <p key={i}><span></span>
                  <span className="choice_correct">
                        {JSON.stringify(this.props.choices[sectionkey]["correctAnswer"]) == 'true'? <span>
                        <i class="far fa-check-circle"></i>
                            </span>: <span>
                            <i class="far fa-circle"></i>
                            </span> }  </span> 
                            <span className="choice_text">
                                {String.fromCharCode(65 + i)}: {
                                (this.props.choices[sectionkey]["text"])}
                                <br />
                  </span>
                  <span className="choice_explanation">
                  {
                  (this.props.choices[sectionkey]["explanation"])}<br /><br />
                  </span>
                </p>
                
              )))}
        </div>
       ) : null 
      }
        </div>);
        }

}
 


// Exporting the component
export default Question;