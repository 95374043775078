// Importing combination
import React, {Component} from 'react';
import axios from 'axios';
import LookerLoadingSpinner from './LookerSpinner.js';
import { Auth } from 'aws-amplify';
import Truncate from 'react-truncate';

class LookerRatings extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tickets: {},
          loading: false,
        }
      }

      componentDidMount() {
        console.log('on component mount');
        //const {
        //  match: { params }
        //} = this.props;
        //const { asanatagid } = (this.props.asanatagid);
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
          //console.log(user);
          this.setState({apiauthtoken: user.signInUserSession.idToken.jwtToken});
        }).catch(e => {
        //  console.log(e);
          this.setState({authState: 'signIn'});
        });
          // for dev purposes just using tag id 1200540718343943
          // workspace 941019801419457
          this.setState({ loading: true }, () => {
          //axios.get("https://app.asana.com/api/1.0/workspaces/941019801419457/tasks/search?opt_fields=gid,completed_at,completed,created_at,assignee,name,tags&tags.any=1200540718343943", { headers: {"Authorization" : `Bearer 1/1172686652505983:b3837dbb5b07a4110713bc71e023b444`} })

            axios.get(`https://awoypdsuzl.execute-api.us-east-1.amazonaws.com/v0/course_ratings?course_name=${this.props.coursename}`, { headers: {"Authorization" : `Bearer ${this.props.apigw_token}`}})
              .then(response => {
                this.setState({ 
                tickets: response.data,
                loading: false, });
              //  console.log(JSON.stringify(response.data));
                console.log(response.status);

              })
              .catch(error => {
             //   console.log(error);
                this.setState({ 
                    tickets: [],
                    loading: false, 
                    });
              });
            });
      }
      render() {
        const { tickets } = (this.state.tickets);
          return (
            <span className="ZenDeskDetails">
               {this.state.loading   //if we are still loading
                      ? <LookerLoadingSpinner /> :  
                      (this.props.coursename != "" && this.props.coursename != "xxxx" && this.state.tickets ? 
                        <div className="zendeskcontent">
                        {Object.keys(this.state.tickets).map((key, i) => (
                            <div className="zd_feedback_wrapper" key={i}>

                              <span className="zd_ticket_id">
                              {new Intl.DateTimeFormat('en-AU', {  timeZone: 'Australia/Sydney', year: 'numeric', month: 'short', day: 'numeric'}).format(new Date(this.state.tickets[key]['zendesk_tickets.created_date']))} 
                              <span className={`zendesk_content_type_${this.state.tickets[key]['f_zendesk_content.content_type']}`}>
                              {this.state.tickets[key]['f_zendesk_content.content_type']} feedback </span> <span className={`zendesk_ticket_${this.state.tickets[key]['zendesk_tickets.status']}`}>
                              {this.state.tickets[key]['zendesk_tickets.status']} ({this.state.tickets[key]['f_ticket_tag__list.resolution']})</span>

                              <br />
                              <span className={`zendesk_ticket_content_name]}`}><b>Lesson: </b> 
                              {this.state.tickets[key]['zendesk_tickets.content_name']}</span>

                              <br />
                              <span className="zendesk_hyperlink" title = "Open task in ZenDesk">
                              <a target="_blank" href={"https://acloudguru.zendesk.com/agent/tickets/" + this.state.tickets[key]['f_zendesk_tags.ticket_id']}>
                              {this.state.tickets[key]['f_zendesk_tags.ticket_id']}    -  
                                {this.state.tickets[key]['zendesk_tickets.subject']}</a> </span></span><br />


                              <span className="zd_ticket_description">
                              "{this.state.tickets[key]['zendesk_tickets.description'].split('Student Comment: ')[1]}" </span>
                              
                              <br />
                              {/*
                             <span className={`zendesk_ticket_course_title']}`}><b>Course: </b> 
                              {this.state.tickets[key]['f_zendesk_content.course_title']}</span><br />
                              */}
                             

                            </div>
                            ))}</div>:<span><br />No ZenDesk Tickets found</span>)}</span>)
    
    }
}


// Exporting the component
export default LookerRatings;